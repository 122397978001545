import React, { useEffect, useState } from "react";
import useAuth from "../hooks/auth/useAuth";
import { useNavigate } from "react-router-dom";
import "../styles/pages/signin.css";
import { authContextType } from "../@types/auth-types";
import Input from "../components/input";
import StatusModal from "../components/status-modal";
import { useApiStatus } from "../hooks/status/useApiStatus";

//pagina de login
export default function Signin() {
  const { signin } = useAuth() as authContextType;
  const navigate = useNavigate();

  const { data, isSuccess, isError } = useApiStatus();
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const images = ["img1.png", "img2.png", "img3.png"];
  const [activeImage, setActiveImage] = useState(0);

  const handleLogin = () => {
    if (!email || !password) {
      setError("Preencha todos os campos");
      return;
    }

    signin(email, password)
      .then((res) => {
        console.log(res);
        navigate("/auth");
      })
      .catch((error) => {
        setError(error);
      });
    //passar para o react query
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveImage(
        (prevActiveImage) => (prevActiveImage + 1) % images.length
      );
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isError || data?.status === "offline") {
      setIsStatusModalOpen(true);
    } else {
      setIsStatusModalOpen(false);
    }
  }, [isSuccess, isError, data]);

  return (
    <div className="background-signin">
      <div className="background-left-signin">
        <label className="left-text-signin">
          Incluindo através do brincar com o <strong>ABACADA</strong>
        </label>
        <img
          src={require(`../assets/login/${images[activeImage]}`)}
          className="left-image-signin"
        />
        <div className="image-options-container-signin">
          <div
            className={
              activeImage == 0
                ? "image-option-selected-signin"
                : "image-option-signin"
            }
          ></div>
          <div
            className={
              activeImage == 1
                ? "image-option-selected-signin"
                : "image-option-signin"
            }
          ></div>
          <div
            className={
              activeImage == 2
                ? "image-option-selected-signin"
                : "image-option-signin"
            }
          ></div>
        </div>
        <img
          src={require("../assets/login/institution-logo.png")}
          alt="Logo das instituição parceiras do projeto"
          className="institution-logo-signin"
        />
      </div>
      <div className="background-right-signin">
        <div className="content-signin">
          <img
            src={require("../assets/logo-azul.png")}
            className="logo-image-signin"
          />

          <Input
            label="Email"
            name="email"
            type="email"
            placeholder="exemplo@gmail.com"
            onChange={(e: React.FormEvent) => [
              setEmail((e.target as HTMLInputElement).value),
              setError(""),
            ]}
          />
          <Input
            label="Senha"
            name="password"
            type="password"
            placeholder=" "
            onChange={(e: React.FormEvent) => [
              setPassword((e.target as HTMLInputElement).value),
              setError(""),
            ]}
          />

          <label className="label-error-signin">{error}</label>
          <input
            type="submit"
            value="Entrar"
            className="button-signin"
            onClick={handleLogin}
          ></input>
        </div>
      </div>
      <StatusModal isOpen={isStatusModalOpen} onOK={() => console.log("ok")} />
    </div>
  );
}
