import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../util/api";

const deleteData = async (id: string) => {
  return await api.delete("/professor?id=" + id);
};

export function useTeacherDelete() {
  const queryClient = useQueryClient();
  const mutate = useMutation({
    mutationFn: deleteData,
    onSuccess: () => {
      queryClient.invalidateQueries(["teachers-data"]);
    },
  });

  return mutate;
}
