import { useMutation } from "@tanstack/react-query";
import api from "../../util/api";
import { postGame } from "../../@types/game-type";

const postData = async (data: postGame) => {
  return await api.post("/jogo", data.game, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  });
};

export function useGameMutate() {
  const mutate = useMutation({
    mutationFn: postData,
  });

  return mutate;
}
