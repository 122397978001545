import React, { useState } from "react";
import "../styles/components/game-modal.css";
import {
  AiOutlineStar,
  AiFillStar,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import PlayerCard from "./player-card";
import { useGameData } from "../hooks/game/useGameData";
import { useStudentData } from "../hooks/student/useStudentData";
import { useFavoriteMutate } from "../hooks/favorite/useFavoriteMutate";
import { useDeleteFavoriteMutate } from "../hooks/favorite/useDeleteFavoriteMutate";
import { gameModalProps } from "../@types/props-types";
import {
  favoriteDeleteRequest,
  favoriteRequest,
} from "../@types/favorite-types";
import useAuth from "../hooks/auth/useAuth";
import { authContextType } from "../@types/auth-types";

function GameModal(props: gameModalProps) {
  const { user } = useAuth() as authContextType;
  const { data: student } = useStudentData(
    user.studentId || null,
    user.token || null
  );

  let isFavorite = false;
  let favoriteID = "";
  const countPlayers = 1;
  const { mutate: favoriteMutate } = useFavoriteMutate();
  const { mutate: favoriteDelete } = useDeleteFavoriteMutate();

  const tam = props.favorites?.length || 0;
  for (let index = 0; index < tam; index++) {
    if (props.favorites?.[index].game.id === props.game?.id) {
      favoriteID = props.favorites?.[index].id || "";
      isFavorite = true;
      break;
    }
  }
  //setIsFavorite(props.favorites?.find((favorite: favoriteType) => favorite.jogo.id === game?.id) ? true : false);
  // if (props.favorites?.find((favorite: favoriteType) => favorite.jogo.id === game?.id)) {
  //   setIsFavorite(true);
  // }

  const handleDeleteFavorite = () => {
    isFavorite = false;
    const data: favoriteDeleteRequest = {
      id: favoriteID,
      token: user.token || "",
    };
    favoriteDelete(data);
  };

  const handleAddFavorite = () => {
    isFavorite = true;
    const data: favoriteRequest = {
      student: user.studentId || undefined,
      game: props.game?.id || undefined,
      token: user.token || "",
    };

    favoriteMutate(data);
  };

  const handleFavorite = () => {
    if (isFavorite) {
      handleDeleteFavorite();
    } else {
      handleAddFavorite();
    }
  };

  return (
    <div className="container-game-modal">
      <div className="data-game-modal">
        <div className="container-close-game-modal">
          <AiOutlineCloseCircle
            className="close-game-modal"
            onClick={props.onClose}
          />
        </div>
        <div className="image-container-game-modal">
          {props.game?.image ? (
            <img
              src={require(`../assets/${props.game?.image}`)}
              alt="Imagem do Jogo"
              className="game-image-game-modal"
            />
          ) : null}
        </div>
        <div className="info-game-modal">
          <div className="main-info-game-modal">
            <label className="title-game-modal">Descrição</label>
            <div className="description-game-modal">
              {props.game?.description}
            </div>
          </div>
          <div className="other-info-game-modal">
            <div className="players-container-game-modal">
              <label className="title-game-modal">Jogadores</label>
              <div className="player-icons-container-game-modal">
                <PlayerCard card={student?.hero.icon || "new-player.png"} />
                {/* {props.game?.players
                  ? Array.from(Array(props.game?.players).keys()).map(
                      (index) => {
                        return (
                          <PlayerCard key={index} card="Homem-Aranha.png" />
                        );
                      }
                    )
                  : null} */}
                {(props.game?.players || 0) > countPlayers && (
                  <PlayerCard card="new-player.png" />
                )}
              </div>
            </div>
            <div className="favorite-container-game-modal">
              <label className="title-game-modal">Favorito</label>
              {isFavorite ? (
                <AiFillStar
                  size={40}
                  color="#FFD700"
                  className="button-favorite-game-modal"
                  onClick={() => handleFavorite()}
                />
              ) : (
                <AiOutlineStar
                  size={40}
                  color="#C9C9C9"
                  className="button-favorite-game-modal"
                  onClick={() => handleFavorite()}
                />
              )}
            </div>
          </div>
          <div className="button-play-container-game-modal">
            <button
              className="button-play-game-modal"
              onClick={props.onClickPlay}
            >
              Jogar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GameModal;
