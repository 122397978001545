import React from "react";
import "../styles/components/game-card.css";
import { gameType } from "../@types/game-type";

interface GameCardProps {
  game: gameType;
  isAllGamesPage: boolean;
  handleClick: (game: gameType) => void;
}

//O componente Game pega os dados dos jogos e os renderiza na tela
function GameCard(props: GameCardProps) {
  return (
    <div className="background-game-card">
      <img
        src={require(`../assets/${props.game.image}`)}
        className="image-game-card"
        onClick={() => props.handleClick(props.game)}
      />
    </div>
  );
}

export default GameCard;
