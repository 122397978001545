import { useQuery } from "@tanstack/react-query";
import api from "../../util/api";
import { favoriteType } from "../../@types/favorite-types";

const fetchData = async (
  id: string | undefined,
  token: string
): Promise<favoriteType[]> => {
  const response = await api.get<favoriteType[]>("/favorito?studentId=" + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export function useFavoriteData(id: string | null, token: string | null) {
  const query = useQuery({
    queryFn: () => (id && token ? fetchData(id, token) : null),
    queryKey: ["favorite-data", id],
    enabled: !!id && !!token,
  });

  return {
    ...query,
    data: query.data,
  };
}
