import { useQuery } from "@tanstack/react-query";
import api from "../../util/api";
import { heroType } from "../../@types/hero-types";

const fetchData = async (token: string): Promise<heroType[]> => {
  const response = await api.get<heroType[]>("/heroi", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export function useHeroesData(token: string | null) {
  const query = useQuery({
    queryFn: () => (token ? fetchData(token) : null),
    queryKey: ["heroes-data"],
  });

  return {
    ...query,
    data: query.data,
  };
}
