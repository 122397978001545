import { useQuery } from "@tanstack/react-query";
import api from "../../util/api";
import { gameType } from "../../@types/game-type";

const fetchData = async (token: string): Promise<gameType[]> => {
    const response = await api.get<gameType[]>("/jogo", {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
};

export function useGamesData(token: string) {
    const query = useQuery({
        queryFn: () => fetchData(token),
        queryKey: ["games-data"]
    });

    return {
        ...query,
        data: query.data
    };
}