import React, { useEffect, useState } from "react";
import GameCardHistoric from "../components/game-card-historic";
import Game from "../components/game";
import "../styles/pages/home.css";
import useAuth from "../hooks/auth/useAuth";
import { useNavigate } from "react-router-dom";
import userServices from "../services/userServices";
import { CgProfile } from "react-icons/cg";
import { BsGear } from "react-icons/bs";
import { BiExit } from "react-icons/bi";
import { AiOutlineUserSwitch } from "react-icons/ai";
import GameModal from "../components/game-modal";
import { useGamesRecommendedData } from "../hooks/game/useGamesRecommendedData";
import { useFavoriteData } from "../hooks/favorite/useFavoriteData";
import { useHistoricData } from "../hooks/historic/useHistoricData";
import { useStudentData } from "../hooks/student/useStudentData";
import AllGamesModal from "../components/all-games-modal";
import GameCard from "../components/game-card";
import { useGameData } from "../hooks/game/useGameData";
import { ToastContainer, toast } from "react-toastify";
import { useHistoricMutate } from "../hooks/historic/useHistoricMutate";
import { useGamesData } from "../hooks/game/useGamesData";
import { authContextType } from "../@types/auth-types";
import { historicRequest } from "../@types/historic-types";
import { gameType } from "../@types/game-type";
import { favoriteType } from "../@types/favorite-types";
import { userType } from "../@types/user-types";
import { studentType } from "../@types/student-types";
import GameRow from "../components/game-row";
import GameRowAllGames from "../components/all-games-row";
import AllGamesRow from "../components/all-games-row";
import ConfirmationModal from "../components/confirmation-modal";

//pagina home
function Home() {
  const vogais = ["A", "O", "U", "I", "E"];
  const estagios = ["Sílaba", "Palavra", "Frase", "Texto"];

  const { user } = useAuth() as authContextType;
  const [firstRender, setFirstRender] = useState(true);

  const { data: student } = useStudentData(user.studentId, user.token);
  const { data: favorites } = useFavoriteData(user.studentId, user.token);

  const [vowel, setVowel] = useState(student?.vowel);
  const [stage, setStage] = useState(student?.stage);
  const { data: allgames } = useGamesData(user.token || "");
  const { data: recommendedGames } = useGamesRecommendedData(
    vowel || undefined,
    stage || undefined,
    user.token || undefined
  );
  // const { data: game } = useGameData(sessionStorage.getItem("jogo") || undefined);
  const {
    mutate: mutateHistoric,
    data: dataHistoric,
    isSuccess,
  } = useHistoricMutate();

  const [isGameModalVisible, setIsGameModalVisible] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [selectedGame, setSelectedGame] = useState<gameType | undefined>(
    undefined
  );

  const navigate = useNavigate();
  const { signout } = useAuth() as authContextType;
  const [error, setError] = useState("");
  const [isGameVisible, setIsGameVisible] = useState(false);

  // const handleOnClickPlay = () => {
  //   const data: historicRequest = {
  //     aluno: student,
  //     jogo: allgames[0],
  //     tempoMin: "",
  //     tempoSeg: "",
  //     concluido: false
  //   };

  //   mutateHistoric(data);
  // };

  const handleOnClose = () => {
    setIsGameModalVisible(false);
    sessionStorage.removeItem("jogo");
    sessionStorage.removeItem("historico");
    sessionStorage.removeItem("url");
  };

  const handleSelectGame = (game: gameType) => {
    setSelectedGame(game);
    setIsGameModalVisible(true);
  };

  const handleExitClick = () => {
    sessionStorage.removeItem("instituicao");
    sessionStorage.removeItem("professor");
    sessionStorage.removeItem("aluno");
    sessionStorage.removeItem("jogo");
    sessionStorage.removeItem("historico");
    signout;
    navigate("/");
  };

  useEffect(() => {
    setStage(student?.stage);
    setVowel(student?.vowel);
  }, [student]);

  // useEffect(() => {
  //   if (firstRender) {
  //     setFirstRender(false);
  //     return;
  //   }
  //   sessionStorage.setItem("url", allgames[0]?.url || "");
  //   dataHistoric?.data.id &&
  //     sessionStorage.setItem("historico", dataHistoric.data.id);
  //   dataHistoric?.data.id && setIsGameVisible(true);
  // }, [isSuccess]);

  return (
    <div className="background-home">
      <div className="background-top-home">
        <div className="container-logo-image-home">
          <img
            src={require("../assets/Logo.png")}
            className="logo-image-home"
          />
        </div>
        <div className="container-hero-card-home">
          {student && (
            <img
              src={require(`../assets/banners/${student?.hero.banner}`)}
              className="banner-home"
            />
          )}
        </div>
        <div className="container-exit-home">
          <BiExit
            className="exit-home"
            onClick={() => setIsConfirmationModalOpen(true)}
          />
          <label className="exit-description">Sair</label>
        </div>
      </div>
      <div className="background-bottom-home">
        <div className="games-container-home">
          <label className="label-section-title-games-home">JOGOS</label>
          {(allgames?.length || 0) > 0 ? (
            <div className="allgames-home">
              <AllGamesRow games={allgames} handleClick={handleSelectGame} />
            </div>
          ) : (
            <div
              className="allgames-home"
              style={{ width: "100%", height: "100%" }}
              onClick={() => setIsGameModalVisible(true)}
            >
              <label className="label-no-games-home">
                {" "}
                O aluno não possui jogos favoritos
              </label>
            </div>
          )}
        </div>
        <div className="other-games-container-home">
          <div className="recommended-games-home">
            <label className="label-section-title-home">RECOMENDADOS</label>
            {(recommendedGames?.length || 0) > 0 ? (
              <GameRow
                games={recommendedGames}
                handleClick={handleSelectGame}
              />
            ) : (
              <label className="label-no-games-home">
                {" "}
                O aluno não possui jogos recomendados
              </label>
            )}
          </div>
          <div className="favorites-games-home">
            <label className="label-section-title-home">FAVORITOS</label>
            {(favorites?.length || 0) > 0 ? (
              <GameRow
                games={favorites?.map((favorite) => favorite.game)}
                handleClick={handleSelectGame}
              />
            ) : (
              <label className="label-no-games-home">
                {" "}
                O aluno não possui jogos favoritos
              </label>
            )}
          </div>
        </div>
      </div>

      <ToastContainer />

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        message="Tem certeza que deseja sair?"
        onConfirm={handleExitClick}
        onCancel={() => setIsConfirmationModalOpen(false)}
      />

      {isGameModalVisible ? (
        <GameModal
          game={selectedGame}
          favorites={favorites || undefined}
          onClickPlay={() => setIsGameVisible(true)}
          onClose={handleOnClose}
        />
      ) : null}
      {isGameVisible ? (
        <Game
          studentId={user.studentId || ""}
          gameId={selectedGame?.id || ""}
          token={user.token || ""}
          gameURL={selectedGame?.url || ""}
          onClose={() => setIsGameVisible(false)}
        />
      ) : null}
    </div>
  );
}

export default Home;
