import React, { useEffect } from "react";
import { Fragment } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import useAuth from "../hooks/auth/useAuth";
import { authContextType } from "../@types/auth-types";
import Home from "../pages/home";
import Games from "../pages/games";
import Signin from "../pages/signin";
import Signup from "../pages/signup";
import Teachers from "../pages/teachers";
import Students from "../pages/students";
import AddTeacher from "../pages/new-teacher";
import AddStudent from "../pages/new-student";
import Admin from "../pages/admin";
import Auth from "../pages/auth";
import NewHero from "../pages/new-hero";
import PageNotFound from "../pages/page-not-found";
import NewGame from "../pages/new-game";

//cria todas as rotas da aplicação
function RoutesApp() {
  const { signed } = useAuth() as authContextType;

  const Private = ({ Item }: any) => {
    return signed ? <Item /> : <Signin />;
  };

  return (
    <BrowserRouter>
      <Fragment>
        <Routes>
          <Route path="/novo-heroi" element={<Private Item={NewHero} />} />
          <Route path="/novo-jogo" element={<NewGame />} />
          <Route path="/home" element={<Private Item={Home} />} />
          <Route path="/jogos" element={<Private Item={Games} />} />
          <Route path="/professores" element={<Private Item={Teachers} />} />
          <Route path="/alunos" element={<Private Item={Students} />} />
          <Route path="/addProfessor" element={<Private Item={AddTeacher} />} />
          <Route path="/addAluno" element={<Private Item={AddStudent} />} />
          <Route path="/" element={<Signin />} />
          <Route path="/signup" element={<Private Item={Signup} />} />
          <Route path="/admin" element={<Private Item={Admin} />} />
          <Route path="/Auth" element={<Private Item={Auth} />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Fragment>
    </BrowserRouter>
  );
}

export default RoutesApp;
