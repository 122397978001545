import { useQuery } from "@tanstack/react-query";
import api from "../../util/api";

const fetchData = async (id: string, token?: string): Promise<string> => {
  console.log("fetching data");
  const response = await api.get<string>("/auth/getRole?userId=" + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export function useGetUserRole(id: string | null, token: string | null) {
  const query = useQuery({
    queryFn: () => (id && token ? fetchData(id, token) : null),
    queryKey: ["user-role", id],
    enabled: !!id && !!token,
  });

  return {
    ...query,
    data: query.data,
  };
}
