import { useQuery } from "@tanstack/react-query";
import api from "../../util/api";
import { studentType } from "../../@types/student-types";

const fetchData = async (id: string, token: string): Promise<studentType[]> => {
  const response = await api.get<studentType[]>("/aluno?teacherId=" + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export function useStudentsData(id: string | null, token: string | null) {
  const query = useQuery({
    queryFn: () => (id && token ? fetchData(id, token) : null),
    queryKey: ["students-data", id],
  });

  return {
    ...query,
    data: query.data,
  };
}
