import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../util/api";
import { favoriteDeleteRequest } from "../../@types/favorite-types";

const deleteData = async (data: favoriteDeleteRequest) => {
  return await api.delete("/favorito?id=" + data.id, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  });
};

export function useDeleteFavoriteMutate() {
  const queryClient = useQueryClient();
  const mutate = useMutation({
    mutationFn: deleteData,
    onSuccess: () => {
      queryClient.invalidateQueries(["favorite-data"]);
    },
  });

  return mutate;
}
