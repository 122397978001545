import React, { useState } from "react";
import Card from "../components/person-card";
import "../styles/pages/students.css";
import { useNavigate } from "react-router-dom";
import { useStudentsData } from "../hooks/student/useStudentsData";
import { AiOutlineUserAdd, AiOutlineUserDelete } from "react-icons/ai";
import BackgroundLogo from "../components/background-logo";
import { BsGearFill } from "react-icons/bs";
import { IoAddCircleOutline, IoRemoveCircleOutline } from "react-icons/io5";
import { ImArrowRight2, ImArrowLeft2 } from "react-icons/im";
import useAuth from "../hooks/auth/useAuth";
import { authContextType } from "../@types/auth-types";

//pagina dos alunos
function Students() {
  const navigate = useNavigate();

  const { user } = useAuth() as authContextType;
  const { data: students } = useStudentsData(user.teacherId, user.token);
  const [selectedStudent, setSelectedStudent] = useState("");

  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false);

  const handleDelete = () => {
    setIsDeleteMode(!isDeleteMode);
    setIsUpdateMode(false);
  };

  const handleOptions = () => {
    setIsOptionsModalOpen(!isOptionsModalOpen);
  };

  const handleAddStudent = () => {
    setIsOptionsModalOpen(false);
    navigate("/addAluno");
  };

  const handleNextPage = () => {
    console.log(user);
    if (user.teacherId && user.studentId) {
      navigate("/home");
    }
  };

  const handleGoBack = () => {
    user.studentId = null;
    user.teacherId = null;
    navigate("/professores");
  };

  const handleSelect = (id: string) => {
    setSelectedStudent(id);
  };

  return (
    <div className="background-students">
      <BackgroundLogo isAdminPage={false} />
      <div className="background-right-students">
        <div className="container-header-students">
          <BsGearFill
            className="options-header-students"
            onClick={handleOptions}
          />
          <div
            className={
              isOptionsModalOpen
                ? "options-container-students-enable"
                : "options-container-students-disable"
            }
          >
            <div className="option-students" onClick={handleAddStudent}>
              <IoAddCircleOutline className="option-image-students" />
              <label>Adicionar</label>
            </div>
            <div className="option-students" onClick={handleOptions}>
              <IoRemoveCircleOutline className="option-image-students" />
              <label>Remover</label>
            </div>
            <div className="option-students" onClick={handleOptions}>
              <IoAddCircleOutline className="option-image-students" />
              <label>Alterar</label>
            </div>
            <div className="option-students" onClick={handleOptions}>
              <IoAddCircleOutline className="option-image-students" />
              <label>Gerenciar</label>
            </div>
          </div>
        </div>
        <div className="container-students">
          <div className="container-content-students">
            <div className="label-title-students">Selecione o Aluno</div>
            <div className="content-students">
              {(students?.length || 0) > 0 ? (
                students?.map((student) => (
                  <Card
                    key={student.id}
                    id={student.id}
                    nome={student.name}
                    imagem={undefined}
                    heroi={student.hero}
                    situation="Aluno"
                    delete={isDeleteMode}
                    update={isUpdateMode}
                    handleSelect={handleSelect}
                    selectedTeacher={null}
                    selectedStudent={selectedStudent}
                  />
                ))
              ) : (
                <div className="label-no-students">
                  <label>
                    Ainda não temos alunos cadastrados. Clique no botão para
                    adicioná-los!
                  </label>
                </div>
              )}
            </div>
            <div className="container-arrows-students">
              <ImArrowLeft2
                size={25}
                onClick={handleGoBack}
                className="arrows-students"
              />
              {(students?.length || 0) > 0 ? (
                <ImArrowRight2
                  size={25}
                  onClick={handleNextPage}
                  className={
                    user.studentId
                      ? "arrows-students"
                      : "arrows-students-disable"
                  }
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Students;
