import { useQuery } from "@tanstack/react-query";
import api from "../../util/api";
import { useEffect } from "react";
import { gameType } from "../../@types/game-type";

const fetchData = async (id?: string, token?: string): Promise<gameType> => {
    const response = await api.get<gameType>("/jogo/"+id, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
};

export function useGameData(id?: string, token?: string) {
    const query = useQuery({
        queryFn: () => fetchData(id, token),
        queryKey: ["game-data"],
        enabled: !!id
    });

    useEffect(() => {
        query.refetch();
    }, [id]);

    return {
        ...query,
        data: query.data
    };
}