import React, { useEffect, useState } from "react";
import useAuth from "../hooks/auth/useAuth";
import { authContextType } from "../@types/auth-types";
import { Link, useNavigate } from "react-router-dom";
import "../styles/pages/signup.css";
import BackgroundLogo from "../components/background-logo";
import Input from "../components/input";
import { ToastContainer, toast } from "react-toastify";

//pagina de cadastro
export default function Signup() {
  const { signup } = useAuth() as authContextType;
  const navigate = useNavigate();

  const [isSuccess, setIsSuccess] = useState(false);

  const [institution, setInstitution] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [error, setError] = useState("");

  const handleSignUp = () => {
    if (!institution || !email || !password || !passwordConfirmation) {
      setError("Preencha todos os campos");
      return;
    }
    if (password === passwordConfirmation) {
      signup(institution, email, password)
        .then((resolve) => setIsSuccess(true))
        .catch((reject) => setError(reject));
    } else {
      setError("Confirme a senha corretamente");
    }
  };

  const handleComeBack = () => {
    navigate("/admin");
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Cadastro realizado com sucesso!");
    }
  }, [isSuccess]);

  return (
    <div className="backgroundSignup">
      <BackgroundLogo isAdminPage={true} />
      <div className="background-rightSignup">
        <div className="contentSignup">
          <label className="labelTitleSignup">Cadastro</label>

          <Input
            label="Instituição"
            name="instituição"
            type="text"
            placeholder=" "
            onChange={(e: React.FormEvent) => [
              setInstitution((e.target as HTMLInputElement).value),
              setError(""),
            ]}
          />

          <Input
            label="Email"
            name="email"
            type="email"
            placeholder="exemplo@gmail.com"
            onChange={(e: React.FormEvent) => [
              setEmail((e.target as HTMLInputElement).value),
              setError(""),
            ]}
          />

          <Input
            label="Senha"
            name="senha"
            type="password"
            placeholder=" "
            onChange={(e: React.FormEvent) => [
              setPassword((e.target as HTMLInputElement).value),
              setError(""),
            ]}
          />

          <Input
            label="Confirme a senha"
            name="confirme a senha"
            type="password"
            placeholder=" "
            onChange={(e: React.FormEvent) => [
              setPasswordConfirmation((e.target as HTMLInputElement).value),
              setError(""),
            ]}
          />

          <label className="labelErrorSignup">{error}</label>
          <div className="buttonsContainerSignup">
            <input
              type="submit"
              value="Voltar"
              className="buttonVoltarSignup"
              onClick={handleComeBack}
            ></input>
            <input
              type="submit"
              value="Enviar"
              className="buttonSignup"
              onClick={handleSignUp}
            ></input>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
