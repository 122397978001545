import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/components/person-card.css";
import { heroType } from "../@types/hero-types";
import { BiPencil } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import userServices from "../services/userServices";
import { useTeacherDelete } from "../hooks/teacher/useTeacherDelete";
import { useStudentDelete } from "../hooks/student/useStudentDelete";
import useAuth from "../hooks/auth/useAuth";
import { authContextType } from "../@types/auth-types";

interface PersonCardProps {
  id: string;
  nome: string;
  imagem?: string;
  heroi?: heroType;
  situation: string;
  delete: boolean;
  update: boolean;
  handleSelect: (id: string) => void;
  selectedTeacher: string | null;
  selectedStudent: string | null;
}

//Componente Card vai pegar as informações do aluno/professor e mostrar na tela
function PersonCard(props: PersonCardProps) {
  const { user } = useAuth() as authContextType;
  const { mutate: deleteTeacher } = useTeacherDelete();
  const { mutate: deleteStudent } = useStudentDelete();

  const images = [
    "user1.png",
    "user2.png",
    "user3.png",
    "user4.png",
    "user5.png",
    "user6.png",
    "user7.png",
  ];
  const [image, setImage] = useState<string>("user1.png");

  const title =
    props.situation == "Professor"
      ? "Professor: " + props.nome + "\nEmail: " + props.id
      : "Aluno: " + props.nome + "\nRegistro: " + props.id;

  function handleClick() {
    props.handleSelect(props.id);

    if (props.delete) {
      handleClickDelete();
    } else if (props.update) {
      handleClickUpdate();
    } else {
      switch (props.situation) {
        case "Professor":
          user.teacherId = props.id;
          break;
        case "Aluno":
          user.studentId = props.id;
          break;
        default:
          break;
      }
    }
  }

  function handleClickDelete() {
    if (props.situation == "Professor") {
      deleteTeacher(props.id);
    } else if (props.situation == "Aluno") {
      deleteStudent(props.id);
    } else {
      alert("ERRO");
    }
  }

  function handleClickUpdate() {
    return alert("update " + props.update);
  }

  function getImage() {
    setImage(images[Math.floor(Math.random() * images.length)]);
  }

  return (
    <div className="content-person-card" onClick={handleClick} title={title}>
      <div
        className={
          props.selectedTeacher == props.id || props.selectedStudent == props.id
            ? "image-container-person-card-selected"
            : "image-container-person-card"
        }
      >
        {props.situation == "Professor" ? (
          props.selectedTeacher == props.id ||
          props.selectedStudent == props.id ? (
            <img src={require("../assets/teachers/user-selected.png")} />
          ) : (
            <img src={require("../assets/teachers/user.png")} />
          )
        ) : (
          <img src={require(`../assets/icons/${props.heroi?.icon}`)} />
        )}
      </div>
      <div className="name-person-card">{props.nome}</div>
    </div>
  );
}

export default PersonCard;
