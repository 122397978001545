import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../util/api";
import { postStudent, studentRequest } from "../../@types/student-types";
import { useState } from "react";


const postData = async (data: postStudent) => {
  return await api.post("/aluno", data.student, {
    headers: {
        Authorization: `Bearer ${data.token}`
    }
  });
};

//faça uma função que retorne o mutate e use o useQueryClient para invalidar a query de alunos e envie o postData como mutationFn e um token

export function useStudentMutate() {
  const queryClient = useQueryClient();
  const mutate = useMutation({
    mutationFn: postData,
    onSuccess: () => {
      queryClient.invalidateQueries(["students-data"]);
    }
  });

  return mutate;
}