import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/pages/new-student.css";
import { useTeacherData } from "../hooks/teacher/useTeacherData";
import { useHeroesData } from "../hooks/hero/useHeroesData";
import { useHeroData } from "../hooks/hero/useHeroData";
import { postStudent, studentRequest } from "../@types/student-types";
import { useStudentMutate } from "../hooks/student/useStudentMutate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BackgroundLogo from "../components/background-logo";
import useAuth from "../hooks/auth/useAuth";
import { authContextType } from "../@types/auth-types";

//pagina de cadastro de aluno
export default function NewStudent() {
  const [name, setName] = useState("");
  const [hero, setHero] = useState("");
  const [vowel, setVowel] = useState("");
  const [stage, setStage] = useState("");
  const [error, setError] = useState("");

  const { user } = useAuth() as authContextType;

  const vogais = ["A", "E", "I", "O", "U"];
  const estagios = ["Sílaba", "Vogal", "Frase", "Texto"];

  const navigate = useNavigate();
  const { data: heroes } = useHeroesData(user.token);
  const { mutate, isSuccess, isError } = useStudentMutate();

  const handleComeBack = () => {
    navigate("/alunos");
  };

  const handleNewStudent = () => {
    if (!hero || !vowel || !stage) {
      setError("Preencha todos os campos");
      return;
    }

    if (user.teacherId && user.token) {
      const student: studentRequest = {
        name: name,
        heroId: hero,
        vowel: vowel,
        stage: stage,
        teacherId: user.teacherId,
      };

      const data: postStudent = {
        student: student,
        token: user.token,
      };

      mutate(data);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      console.log("isSuccess: ", isSuccess);
      toast.success("Aluno(a) cadastrado(a) com sucesso!");
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error(
        "Aluno(a) não pôde ser cadastrado(a) com sucesso, tente novamente!"
      );
    }
  }, [isError]);

  return (
    <div className="background-new-student">
      <BackgroundLogo isAdminPage={false} />
      <div className="background-right-new-student">
        <div className="content-new-student">
          <label className="label-title-new-student">Cadastro de Aluno</label>

          <label className="label-new-student">Nome</label>
          <input
            type="text"
            name="nome"
            className="input-new-student"
            placeholder="Digite o Nome"
            onChange={(e: React.FormEvent) => [
              setName((e.target as HTMLInputElement).value),
              setError(""),
            ]}
          ></input>

          <label className="label-new-student">Heroi</label>
          <select
            className="input-new-student"
            required
            defaultValue=""
            onChange={(e) => setHero(e.target.value)}
          >
            <option value="" disabled>
              Selecione...
            </option>
            {heroes?.map((heroi) => (
              <option key={heroi.id} value={heroi.id}>
                {heroi.name}
              </option>
            ))}
          </select>

          <label className="label-new-student">Vogal</label>
          <select
            className="input-new-student"
            required
            defaultValue=""
            onChange={(e) => setVowel(e.target.value)}
          >
            <option value="" disabled>
              Selecione...
            </option>
            {vogais.map((vogal) => (
              <option key={vogal} value={vogal}>
                {vogal}
              </option>
            ))}
          </select>

          <label className="label-new-student">Estagio</label>
          <select
            className="input-new-student"
            required
            defaultValue=""
            onChange={(e) => setStage(e.target.value)}
          >
            <option value="" disabled>
              Selecione...
            </option>
            {estagios.map((estagio, index) => (
              <option key={estagio} value={estagio}>
                {index + 1 + " - " + estagio}
              </option>
            ))}
          </select>

          <label className="label-error-new-student">{error}</label>
          <div className="buttons-container-new-student">
            <input
              type="submit"
              value="Voltar"
              className="button-go-back-new-student"
              onClick={handleComeBack}
            ></input>
            <input
              type="submit"
              value="Cadastrar"
              className="button-new-student"
              onClick={handleNewStudent}
            ></input>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
