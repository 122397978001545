import React, { useEffect, useState } from "react";
import "../styles/pages/auth.css";
import useAuth from "../hooks/auth/useAuth";
import { authContextType } from "../@types/auth-types";
import { useGetUserRole } from "../hooks/auth/useGetUserRole";
import { useNavigate } from "react-router-dom";

export default function Auth() {
  const navigate = useNavigate();
  const { user } = useAuth() as authContextType;
  const { data, isSuccess } = useGetUserRole(user.userId, user.token);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const handleNavigate = () => {
    if (data === "admin") {
      navigate("/admin");
    } else {
      navigate("/professores");
    }
  };

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    } else {
      handleNavigate();
    }
  }, [isSuccess]);

  return (
    <div className="container-loading">
      <div className="loader"></div>
      <label>Redirecionando...</label>
    </div>
  );
}
