import React, { useState } from "react";
import "../styles/components/background-logo.css";
import { ImExit } from "react-icons/im";
import useAuth from "../hooks/auth/useAuth";
import { authContextType } from "../@types/auth-types";
import ConfirmationModal from "./confirmation-modal";

interface BackgroundLogoProps {
  isAdminPage: boolean;
}

function BackgroundLogo(props: BackgroundLogoProps) {
  const { signout } = useAuth() as authContextType;
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const handleExitClick = () => {
    signout();
    window.location.href = "/";
  };

  return (
    <div className="background-logo">
      <ImExit
        className="exit-icon-background-logo"
        onClick={() => setIsConfirmationModalOpen(true)}
      />

      {props.isAdminPage ? (
        <>
          <label className="text-normal-background-logo">
            O que temos para hoje,
          </label>
          <label className="text-bold-background-logo">administrador?</label>
        </>
      ) : (
        <>
          <label className="text-normal-background-logo">quem está</label>
          <label className="text-bold-background-logo">utilizando?</label>
        </>
      )}

      <img
        src={
          props.isAdminPage
            ? require("../assets/background-logo-admin.png")
            : require("../assets/img-professores.png")
        }
        className="background-image"
      />
      <img src={require("../assets/Logo.png")} className="logo-image" />
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        message="Tem certeza que deseja sair?"
        onConfirm={handleExitClick}
        onCancel={() => setIsConfirmationModalOpen(false)}
      />
    </div>
  );
}

export default BackgroundLogo;
