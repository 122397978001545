import { useQuery } from "@tanstack/react-query";
import api from "../../util/api";
import { useEffect } from "react";
import { gameType } from "../../@types/game-type";

const fetchData = async (
  vowel?: string,
  stage?: string,
  token?: string
): Promise<gameType[]> => {
  const response = await api.get<gameType[]>(
    "/jogo/recomendados?vowel=" + vowel + "&stage=" + stage,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export function useGamesRecommendedData(
  vowel?: string,
  stage?: string,
  token?: string
) {
  const query = useQuery({
    queryFn: () => fetchData(vowel, stage, token),
    queryKey: ["games-recommended-data"],
    enabled: !!vowel && !!stage,
  });

  useEffect(() => {
    query.refetch();
  }, [vowel, stage]);

  return {
    ...query,
    data: query.data,
  };
}
