import React, { useState } from "react";
import "../styles/components/all-games-row.css";
import GameCard from "./game-card";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import { gameType } from "../@types/game-type";

interface allGamesRowProps {
  games: gameType[] | undefined;
  handleClick: (game: gameType) => void;
}

export default function AllGamesRow(props: allGamesRowProps) {
  const isPar = (props.games?.length || 0) % 2 === 0;
  const middleIndex = isPar
    ? (props.games?.length || 0) / 2
    : (props.games?.length || 0) / 2 + 1;
  const windowWidth = window.innerWidth * 0.8;
  const upperGamesArray = props.games?.slice(0, middleIndex) || [];
  const bottomGamesArray =
    props.games?.slice(middleIndex, props.games?.length) || [];
  const [scrollX, setScrollX] = useState(0);
  const maxWidthUpper: number = (upperGamesArray.length || 0) * 300;
  const maxWidthBottom: number = (bottomGamesArray.length || 0) * 300;
  const maxWidth: number =
    maxWidthUpper > maxWidthBottom ? maxWidthUpper : maxWidthBottom;

  function handleMoveLeft() {
    let x = scrollX + 300;
    if (x > 0) {
      x = 0;
    }
    setScrollX(x);
  }

  const handleMoveRight = () => {
    let x = scrollX - 300;
    if (windowWidth - maxWidth > x) {
      x = windowWidth - maxWidth;
    }
    setScrollX(x);
  };

  return (
    <div className="list-area-all-games-row">
      {maxWidth > windowWidth ? (
        <MdNavigateBefore
          className="navigate-before-all-games-row"
          onClick={handleMoveLeft}
        />
      ) : null}
      <div className="container-list-all-games-row">
        <div
          className="list-all-games-row"
          style={{
            width: maxWidthUpper,
            marginLeft: scrollX,
          }}
        >
          {upperGamesArray &&
            upperGamesArray.map((game) => (
              <GameCard
                key={game.id}
                game={game}
                isAllGamesPage={false}
                handleClick={props.handleClick}
              />
            ))}
        </div>
        <div
          className="list-all-games-row"
          style={{
            width: maxWidthBottom,
            marginLeft: scrollX,
          }}
        >
          {bottomGamesArray &&
            bottomGamesArray.map((game) => (
              <GameCard
                key={game.id}
                game={game}
                isAllGamesPage={false}
                handleClick={props.handleClick}
              />
            ))}
        </div>
      </div>
      {maxWidth > windowWidth ? (
        <MdNavigateNext
          className="navigate-next-all-games-row"
          onClick={handleMoveRight}
        />
      ) : null}
    </div>
  );
}
