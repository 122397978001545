import React from "react";
import "../styles/pages/page-not-found.css";
import { useNavigate } from "react-router-dom";

export default function PageNotFound() {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/");
  };

  return (
    <div className="background-page-not-found">
      <h1>OPS! NÃO ENCONTRAMOS ESSA PÁGINA</h1>
      <p>A página solicitada não foi encontrada em nosso sistema.</p>
      <input
        type="submit"
        value="VOLTAR PARA O LOGIN"
        className="button-page-not-found"
        onClick={handleNavigate}
      ></input>
    </div>
  );
}
