import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../util/api";
import { postTeacher } from "../../@types/teacher-types";
import { useState } from "react";


const postData = async (data: postTeacher) => {
    return await api.post("/professor", data.teacher, {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    });
};

export function useTeacherMutate() {
  const queryClient = useQueryClient();
  const mutate =  useMutation({
    mutationFn: postData,
    onSuccess: () => {
      queryClient.invalidateQueries(["teachers-data"]);
    }
  });

  return mutate;
}