import React from "react";
import "../styles/components/confirmation-modal.css";

interface confirmationModalProps {
  isOpen: boolean;
  message: string;
  onClose: () => void;
  onConfirm: () => void;
  onCancel: () => void;
}

export default function ConfirmationModal(props: confirmationModalProps) {
  return (
    <>
      {props.isOpen && (
        <div className="background-confirmation-modal">
          <div className="content-confirmation-modal">
            <div className="header-confirmation-modal" onClick={props.onClose}>
              <label>X</label>
            </div>
            <div className="image-container-confirmation-modal">
              <img
                src={require("../assets/attention.png")}
                className="image-confirmation-modal"
              />
            </div>
            <div className="title-confirmation-modal">
              <h2>Atenção!</h2>
            </div>
            <div className="message-confirmation-modal">
              <p>{props.message}</p>
            </div>
            <div className="buttons-confirmation-modal">
              <button
                className="button-cancel-confirmation-modal"
                onClick={props.onCancel}
              >
                NÃO
              </button>
              <button
                className="button-confirm-confirmation-modal"
                onClick={props.onConfirm}
              >
                SIM
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
