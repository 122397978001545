import React from "react";
import "../styles/components/status-modal.css";

interface statusModalProps {
  isOpen: boolean;
  onOK: () => void;
}

export default function StatusModal(props: statusModalProps) {
  return (
    <>
      {props.isOpen && (
        <div className="background-status-modal">
          <div className="content-status-modal">
            <div className="image-container-status-modal">
              <img
                src={require("../assets/attention.png")}
                className="image-status-modal"
              />
            </div>
            <div className="title-status-modal">
              <h2>Atenção!</h2>
            </div>
            <div className="message-status-modal">
              <p>
                Nosso sistema está fora do ar no momento, tente novamente mais
                tarde!
              </p>
            </div>
            <div className="buttons-status-modal">
              <button
                className="button-confirm-status-modal"
                onClick={props.onOK}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
