import React from "react";
import "../styles/pages/admin.css";
import BackgroundLogo from "../components/background-logo";
import { useNavigate } from "react-router-dom";

export default function Admin() {
  const navigate = useNavigate();

  return (
    <div className="background-admin">
      <BackgroundLogo isAdminPage={true} />
      <div className="background-right-admin">
        <div className="content-admin">
          <h1>ADMINISTRAÇÃO</h1>
          <input
            type="submit"
            value="Adicionar Instituição"
            className="button-admin"
            onClick={() => navigate("/signup")}
          ></input>
          <input
            type="submit"
            value="Adicionar Jogo"
            className="button-admin"
          ></input>
          <input
            type="submit"
            value="Adicionar Herói"
            className="button-admin"
            onClick={() => navigate("/novo-heroi")}
          ></input>
        </div>
      </div>
    </div>
  );
}
