import React, { useEffect, useState } from "react";
import "../styles/pages/new-game.css";
import BackgroundLogo from "../components/background-logo";
import Input from "../components/input";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/auth/useAuth";
import { authContextType } from "../@types/auth-types";
import { useGameMutate } from "../hooks/game/useGameMutate";
import { gameRequest, postGame } from "../@types/game-type";

export default function NewGame() {
  const { user } = useAuth() as authContextType;
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [vowel, setVowel] = useState("");
  const [stage, setStage] = useState("");
  const [error, setError] = useState("");
  const { mutate, isSuccess } = useGameMutate();

  const handleNewGame = () => {
    if (!name || !description || !vowel || !stage) {
      setError("Preencha o campo obrigatório");
      return;
    }

    if (user.token) {
      const game: gameRequest = {
        name,
        description,
        stage,
        vowel,
      };

      const postGame: postGame = {
        game,
        token: user.token,
      };

      mutate(postGame);
    }
  };

  const handleComeBack = () => {
    navigate("/admin");
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Novo jogo adicionado com sucesso!");
    }
  }, [isSuccess]);

  return (
    <div className="background-new-game">
      <BackgroundLogo isAdminPage={true} />
      <div className="background-right-new-game">
        <div className="content-new-game">
          <label className="label-title-new-game">Novo Jogo</label>

          <Input
            label="Nome"
            name="Name"
            type="text"
            placeholder=" "
            onChange={(e: React.FormEvent) => [
              setName((e.target as HTMLInputElement).value),
              setError(""),
            ]}
          />

          <Input
            label="Descrição"
            name="Description"
            type="text"
            placeholder=" "
            onChange={(e: React.FormEvent) => [
              setDescription((e.target as HTMLInputElement).value),
              setError(""),
            ]}
          />

          <label className="label-error-new-game">{error}</label>
          <div className="buttons-container-new-game">
            <input
              type="submit"
              value="Voltar"
              className="button-go-back-new-game"
              onClick={handleComeBack}
            ></input>
            <input
              type="submit"
              value="Enviar"
              className="button-new-game"
              onClick={handleNewGame}
            ></input>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
