import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../util/api";
import { favoriteRequest } from "../../@types/favorite-types";
import { gameType } from "../../@types/game-type";
import { studentType } from "../../@types/student-types";

interface dataRequest {
  gameId: string | undefined;
  studentId: string | undefined;
}

const postData = async (data: favoriteRequest) => {
  const request: dataRequest = {
    gameId: data.game,
    studentId: data.student,
  };
  return await api.post("/favorito", request, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  });
};

export function useFavoriteMutate() {
  const queryClient = useQueryClient();
  const mutate = useMutation({
    mutationFn: postData,
    onSuccess: () => {
      queryClient.invalidateQueries(["favorite-data"]);
    },
  });

  return mutate;
}
