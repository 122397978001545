import { useQuery } from "@tanstack/react-query";
import api from "../../util/api";
import { statusType } from "../../@types/status-types";

const fetchData = async (): Promise<statusType> => {
  console.log("fetching data");
  const response = await api.get("/status");
  return response.data;
};

export function useApiStatus() {
  const query = useQuery({
    queryFn: fetchData,
    queryKey: ["api-status"],
    refetchInterval: 1000 * 60,
  });

  return {
    ...query,
    data: query.data,
  };
}
