import React, { useState } from "react";
import "../styles/components/game-row.css";
import GameCard from "./game-card";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import { gameType } from "../@types/game-type";

interface gameRowProps {
  games: gameType[] | undefined;
  handleClick: (game: gameType) => void;
}

export default function GameRow(props: gameRowProps) {
  const [scrollX, setScrollX] = useState(0);
  const windowWidth = window.innerWidth * 0.39;
  const maxWidth: number = (props.games?.length || 0) * 300;

  const handleMoveLeft = () => {
    let x = scrollX + 300;
    if (x > 0) {
      x = 0;
    }
    setScrollX(x);
  };

  const handleMoveRight = () => {
    let x = scrollX - 300;
    if (windowWidth - maxWidth > x) {
      x = windowWidth - maxWidth;
    }
    setScrollX(x);
  };

  return (
    <div className="list-area-game-row">
      {maxWidth > windowWidth ? (
        <MdNavigateBefore
          className="navigate-before-game-row"
          onClick={handleMoveLeft}
        />
      ) : null}
      <div
        className="list-game-row"
        style={{
          width: maxWidth,
          marginLeft: scrollX,
        }}
      >
        {(props.games?.length || 0) > 0 &&
          props.games?.map((game) => (
            <GameCard
              key={game.id}
              game={game}
              isAllGamesPage={false}
              handleClick={props.handleClick}
            />
          ))}
      </div>
      {maxWidth > windowWidth ? (
        <MdNavigateNext
          className="navigate-next-game-row"
          onClick={handleMoveRight}
        />
      ) : null}
    </div>
  );
}
