import { useQuery } from "@tanstack/react-query";
import api from "../../util/api";
import { useEffect } from "react";
import { studentType } from "../../@types/student-types";

const fetchData = async (id: string, token?: string): Promise<studentType> => {
  const response = await api.get<studentType>("/aluno/" + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export function useStudentData(id: string | null, token: string | null) {
  const query = useQuery({
    queryFn: () => (id && token ? fetchData(id, token) : null),
    queryKey: ["student-data", id],
    enabled: !!id && !!token,
  });

  useEffect(() => {
    query.refetch();
  }, [id]);

  return {
    ...query,
    data: query.data,
  };
}
