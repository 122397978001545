import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/pages/new-teacher.css";
import { useTeacherMutate } from "../hooks/teacher/useTeacherMutate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import BackgroundLogo from "../components/background-logo";
import ReactDOM from "react-dom";
import { postTeacher, teacherRequest } from "../@types/teacher-types";
import useAuth from "../hooks/auth/useAuth";
import { authContextType } from "../@types/auth-types";

//pagina de cadastro de professor
export default function NewTeacher() {
  // const { user } = useContext(AuthContext) as AuthContextType;
  const { user } = useAuth() as authContextType;
  const navigate = useNavigate();
  const { mutate, isSuccess, isError } = useTeacherMutate();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const images = [
    "user1.png",
    "user2.png",
    "user3.png",
    "user4.png",
    "user5.png",
    "user6.png",
    "user7.png",
  ];
  const lastPosition = images.length - 1;
  const [position, setPosition] = useState(0);
  // const [beforeSelectedImage, setBeforeSelectedImage] = useState(images[lastPosition]);
  const [selectedImage, setSelectedImage] = useState(images[position]);
  // const [afterSelectedImage, setAfterSelectedImage] = useState(images[position + 1]);

  const handleComeBack = () => {
    navigate("/professores");
  };

  const handleNewTeacher = () => {
    console.log("name: ", name);
    if (!name || !email) {
      console.log("erro");
      setError("Preencha todos os campos");
      return;
    }
    console.log(user);
    if (user.userId && user.token) {
      console.log("entrou");
      const teacher: teacherRequest = {
        name: name,
        email: email,
        image: selectedImage,
        userId: user.userId,
      };

      const data: postTeacher = {
        teacher: teacher,
        token: user.token,
      };

      console.log("data: ", data.teacher);
      mutate(data);
    }
  };

  const handleMoveRightImage = () => {
    if (position < lastPosition) {
      setPosition(position + 1);
      // setBeforeSelectedImage(selectedImage);
      setSelectedImage(images[position]);
      // setAfterSelectedImage(position == lastPosition ? images[0] : images[position + 1]);
    } else {
      setPosition(0);
      // setBeforeSelectedImage(images[lastPosition]);
      setSelectedImage(images[position]);
      // setAfterSelectedImage(images[position + 1]);
    }
  };

  const handleMoveLeftImage = () => {
    if (position > 0) {
      setPosition(position - 1);
      // setBeforeSelectedImage(position == 0 ? images[lastPosition] : images[position - 1]);
      setSelectedImage(images[position]);
      // setAfterSelectedImage(images[position + 1]);
    } else {
      setPosition(lastPosition);
      // setBeforeSelectedImage(images[lastPosition - 1]);
      setSelectedImage(images[position]);
      // setAfterSelectedImage(images[0]);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Professor(a) cadastrado(a) com sucesso!");
      setName("");
      setEmail("");
      setPosition(0);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error(
        "Professor(a) não pôde ser cadastrado(a) com sucesso, tente novamente!"
      );
    }
  }, [isError]);

  return (
    <div className="background-new-teacher">
      <BackgroundLogo isAdminPage={false} />
      <div className="background-right-new-teacher">
        <div className="content-new-teacher">
          <label className="label-title-new-teacher">
            Cadastro de Professor
          </label>
          <div className="conteiner-image-new-teacher">
            {/* <img src={require(`../assets/${beforeSelectedImage}`)} className="person-image-new-teacher"/>
            <label>{beforeSelectedImage}</label> */}
            <img
              src={
                selectedImage && require(`../assets/teachers/${selectedImage}`)
              }
              className="person-image-new-teacher"
            />
            {/* <label>{selectedImage}</label>
            <img src={afterSelectedImage && require(`../assets/${afterSelectedImage}`)} className="person-image-new-teacher"/>
            <label>{afterSelectedImage}</label> */}
            <div className="conteiner-arrows-new-teacher">
              <FaArrowLeft
                className="arrow-new-teacher"
                onClick={handleMoveLeftImage}
              />
              <FaArrowRight
                className="arrow-new-teacher"
                onClick={handleMoveRightImage}
              />
            </div>
          </div>

          <label className="label-new-teacher">Nome</label>
          <input
            type="text"
            name="nome"
            className="input-new-teacher"
            placeholder="Digite o Nome"
            value={name}
            onChange={(e: React.FormEvent) => [
              setName((e.target as HTMLInputElement).value),
              setError(""),
            ]}
          ></input>

          <label className="label-new-teacher">Email</label>
          <input
            type="email"
            name="email"
            className="input-new-teacher"
            placeholder="Digite o Email"
            value={email}
            onChange={(e: React.FormEvent) => [
              setEmail((e.target as HTMLInputElement).value),
              setError(""),
            ]}
          ></input>

          <label className="label-error-new-teacher">{error}</label>
          <div className="buttons-container-new-teacher">
            <input
              type="submit"
              value="Voltar"
              className="button-go-back-new-teacher"
              onClick={handleComeBack}
            ></input>
            <input
              type="submit"
              value="Cadastrar"
              className="button-new-teacher"
              onClick={handleNewTeacher}
            ></input>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
