import React, { useEffect, useState } from "react";
import "../styles/pages/new-hero.css";
import BackgroundLogo from "../components/background-logo";
import Input from "../components/input";
import useAuth from "../hooks/auth/useAuth";
import { authContextType } from "../@types/auth-types";
import { useNavigate } from "react-router-dom";
import { useHeroMutate } from "../hooks/hero/useHeroMutate";
import { ToastContainer, toast } from "react-toastify";
import { heroRequest, postHero } from "../@types/hero-types";

export default function NewHero() {
  const { user } = useAuth() as authContextType;
  const navigate = useNavigate();
  const [heroName, setHeroName] = useState("");
  const [error, setError] = useState("");
  const { mutate, isSuccess } = useHeroMutate();

  const handleNewHero = () => {
    if (!heroName) {
      setError("Preencha o campo obrigatório");
      return;
    }

    if (user.token) {
      const hero: heroRequest = {
        name: heroName,
        icon: heroName + ".png",
        banner: heroName + ".png",
      };

      const postHero: postHero = {
        hero,
        token: user.token,
      };

      mutate(postHero);
    }
  };

  const handleComeBack = () => {
    navigate("/admin");
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Novo herói adicionado com sucesso!");
    }
  }, [isSuccess]);

  return (
    <div className="background-new-hero">
      <BackgroundLogo isAdminPage={true} />
      <div className="background-right-new-hero">
        <div className="content-new-hero">
          <label className="label-title-new-hero">Novo Herói</label>

          <Input
            label="Herói"
            name="Herói"
            type="text"
            placeholder=" "
            onChange={(e: React.FormEvent) => [
              setHeroName((e.target as HTMLInputElement).value),
              setError(""),
            ]}
          />

          <label className="label-error-new-hero">{error}</label>
          <div className="buttons-container-new-hero">
            <input
              type="submit"
              value="Voltar"
              className="button-go-back-new-hero"
              onClick={handleComeBack}
            ></input>
            <input
              type="submit"
              value="Enviar"
              className="button-new-hero"
              onClick={handleNewHero}
            ></input>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
