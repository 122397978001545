import React, { useEffect, useRef, useState } from "react";
import Iframe from "react-iframe";
import "../styles/components/game.css";

interface GameProps {
  onClose: () => void;
  gameURL: string;
  studentId: string;
  gameId: string;
  token: string;
}

//Cria o modal dos jogos recentes
// eslint-disable-next-line react/prop-types
function Game(props: GameProps) {
  window.addEventListener("message", (event) => {
    if (event.data.type === "closeGame") {
      props.onClose();
    }
  });

  const url =
    props.gameURL +
    `?aluno=${props.studentId}&jogo=${props.gameId}&token=${props.token}`;
  console.log(url);

  return (
    <div className="container-game">
      <Iframe
        url={url}
        width="100%"
        height="100%"
        frameBorder={0}
        className="iframe-game"
      />
    </div>
  );
}

export default Game;
