import { useMutation } from "@tanstack/react-query";
import api from "../../util/api";
import { postHero } from "../../@types/hero-types";

const postData = async (data: postHero) => {
  return await api.post("/heroi", data.hero, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  });
};

export function useHeroMutate() {
  const mutate = useMutation({
    mutationFn: postData,
  });

  return mutate;
}
