import React from "react";
import "../styles/components/input.css";

interface InputProps {
  label: string;
  name: string;
  type: string;
  placeholder: string;
  onChange: (e: React.FormEvent) => void;
}

export default function Input({
  label,
  name,
  type,
  placeholder,
  onChange,
}: InputProps) {
  return (
    <div className="container-input">
      <input
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
        onChange={onChange}
        required
      ></input>
      <label htmlFor={name}>{label}</label>
    </div>
  );
}
