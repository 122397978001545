import React, { useState } from "react";
import Card from "../components/person-card";
import "../styles/pages/teachers.css";
import { useNavigate } from "react-router-dom";
import { useTeachersData } from "../hooks/teacher/useTeachersData";
import { BsGearFill } from "react-icons/bs";
import { IoRemoveCircleOutline, IoAddCircleOutline } from "react-icons/io5";
import { ImArrowRight2 } from "react-icons/im";
import BackgroundLogo from "../components/background-logo";
import ConfirmationModal from "../components/confirmation-modal";
import useAuth from "../hooks/auth/useAuth";
import { authContextType } from "../@types/auth-types";

//pagina dos professores
function Teachers() {
  const navigate = useNavigate();
  const { user } = useAuth() as authContextType;
  console.log(user);
  const { data: teachers, isError } = useTeachersData(user.userId, user.token);
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState("");

  const handleDelete = () => {
    setIsDeleteMode(!isDeleteMode);
    setIsUpdateMode(false);
  };

  const handleOptions = () => {
    setIsOptionsModalOpen(!isOptionsModalOpen);
  };

  const handleAddTeacher = () => {
    setIsOptionsModalOpen(false);
    navigate("/addProfessor");
  };

  const handleNextPage = () => {
    navigate("/alunos");
  };

  const handleSelect = (id: string) => {
    setSelectedTeacher(id);
  };

  return (
    <div className="background-teachers">
      <BackgroundLogo isAdminPage={false} />
      <div className="background-right-teachers">
        <div className="container-header-teachers">
          <BsGearFill
            className="options-header-teachers"
            onClick={handleOptions}
          />
          <div
            className={
              isOptionsModalOpen
                ? "options-container-teachers-enable"
                : "options-container-teachers-disable"
            }
            onClick={handleOptions}
          >
            <div className="option-teachers" onClick={handleAddTeacher}>
              <IoAddCircleOutline className="option-image-teachers" />
              <label>Adicionar</label>
            </div>
            <div className="option-teachers" onClick={handleOptions}>
              <IoRemoveCircleOutline className="option-image-teachers" />
              <label>Remover</label>
            </div>
            <div className="option-teachers" onClick={handleOptions}>
              <IoAddCircleOutline className="option-image-teachers" />
              <label>Alterar</label>
            </div>
          </div>
        </div>
        <div className="container-teachers">
          <div className="container-content-teachers">
            <div className="label-title-teachers">Selecione o Professor</div>
            <div className="content-teachers">
              {(teachers?.length || 0) > 0 ? (
                teachers?.map((teacher) => (
                  <Card
                    key={teacher.id}
                    id={teacher.id}
                    nome={teacher.name}
                    imagem={teacher.image}
                    heroi={undefined}
                    situation="Professor"
                    delete={isDeleteMode}
                    update={isUpdateMode}
                    handleSelect={handleSelect}
                    selectedTeacher={selectedTeacher}
                    selectedStudent={null}
                  />
                ))
              ) : (
                <div className="label-no-teachers">
                  <label>
                    Ainda não temos professores cadastrados. Clique na
                    engrenagem e adicione os professores!
                  </label>
                </div>
              )}
            </div>
            <div className="container-arrows-teachers">
              {(teachers?.length || 0) > 0 ? (
                <ImArrowRight2
                  size={30}
                  className={
                    user.teacherId ? "arrow-teachers" : "arrow-teachers-disable"
                  }
                  onClick={handleNextPage}
                />
              ) : null}
            </div>
          </div>
        </div>
        {/* {isConfirmationModalOpen &&
          <ConfirmationModal 
            title="Remover Professor"
            message="Tem certeza que deseja remover o professor?"
            onConfirm={onConfirmConfirmationModal}
            onCancel={onCancelConfirmationModal}
          />
        } */}
      </div>
    </div>
  );
}

export default Teachers;
