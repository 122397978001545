import { useQuery } from "@tanstack/react-query";
import api from "../../util/api";
import { teacherType } from "../../@types/teacher-types";
import { error } from "console";

const fetchData = async (id: string, token: string): Promise<teacherType[]> => {
  const response = await api.get<teacherType[]>(
    "/professor?institutionId=" + id,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export function useTeachersData(id: string | null, token: string | null) {
  const query = useQuery({
    queryFn: () => (id && token ? fetchData(id, token) : null),
    queryKey: ["teachers-data", id],
  });

  return {
    ...query,
    data: query.data,
  };
}
