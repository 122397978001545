import React from "react";
import "../styles/components/player-card.css";

interface playerCardProps {
  card: string;
}

export default function PlayerCard(props: playerCardProps) {
  return (
    <div className="container-player-card">
      <img
        src={require(`../assets/icons/${props.card}`)}
        alt="Player card"
        className="image-player-card"
      />
    </div>
  );
}
