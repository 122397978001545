import React from "react";
import api from "../../util/api";
import { createContext, useState } from "react";
import { userType } from "../../@types/user-types";
import { dataContextType } from "../../@types/auth-types";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext({});

// cria o contexto de usuario, permitindo que os dados sejam utilizados dentro da aplicação
function AuthProvider({ children }: any) {
  const [user, setUser] = useState<dataContextType>({
    token: null,
    userId: null,
    teacherId: null,
    studentId: null,
  });
  const [signed, setSigned] = useState(false);

  const signin = (email: string, password: string) => {
    return new Promise((resolve, reject) => {
      api
        .post("/auth/login", {
          email: email,
          password: password,
        })
        .then((res) => {
          setSigned(true);
          setUser({
            ...user,
            token: res.data.token,
            userId: res.data.institution,
          });
          resolve(res.data);
        })
        .catch((error) => {
          reject(error.response.data.message);
        });
    });
  };

  const signup = (
    institution: string,
    email: string,
    password: string,
    token: string
  ) => {
    return new Promise((resolve, reject) => {
      api
        .post(
          "/auth/cadastro",
          {
            institution,
            email,
            password,
            role: "USER",
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        )
        .then((res) => {
          resolve("");
        })
        .catch((error) => {
          reject(error.response.data.message);
        });
    });
  };

  const signout = () => {
    setUser({
      token: null,
      userId: null,
      teacherId: null,
      studentId: null,
    });
    setSigned(false);
  };

  return (
    <AuthContext.Provider value={{ user, signed, signin, signup, signout }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
